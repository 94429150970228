var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"sidebar",class:[_vm.isOpen ? 'open' : '', _vm.wide ? 'wide' : '', _vm.device !== 'desktop' ? 'full-wide' : ''],attrs:{"id":"menu-bar"}},[_c('UnitSelector',{directives:[{name:"show",rawName:"v-show",value:(_vm.device === 'mobile'),expression:"device === 'mobile'"}],attrs:{"variant":_vm.variant,"device":_vm.device,"closeDropdown":_vm.closeDropdown}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.isOpen ? true : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.Items.Dashboard'); })
      ),expression:"\n        isOpen ? true : !overflowNavItems.some((item) => item.name == $t('Menu.Items.Dashboard'))\n      "}],attrs:{"name":_vm.$t('Menu.Items.Overview'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/dashboard'),"icon":_vm.icons.DashboardIcon,"data-redirect":"my-connect-dashboard","tooltipPlacement":"right","strokeHover":""},on:{"click":function () { return _vm.redirect('my-connect-dashboard'); }}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.isOpen ? true : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.Items.DataSource'); })
      ),expression:"\n        isOpen ? true : !overflowNavItems.some((item) => item.name == $t('Menu.Items.DataSource'))\n      "}],attrs:{"name":_vm.$t('Menu.Items.DataSource'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/data-source'),"icon":_vm.icons.DataSourceIcon,"data-redirect":"data-source","tooltipPlacement":"right"},on:{"click":function () { return _vm.redirect('data-source'); }}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.isOpen ? true : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.Items.Pipelines'); })
      ),expression:"\n        isOpen ? true : !overflowNavItems.some((item) => item.name == $t('Menu.Items.Pipelines'))\n      "}],attrs:{"name":_vm.$t('Menu.Items.Pipelines'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/pipelines'),"icon":_vm.icons.HierarchyIcon,"data-redirect":"pipelines","tooltipPlacement":"right","strokeHover":""},on:{"click":function () { return _vm.redirect('pipelines'); }}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.isOpen ? true : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.Items.LogCenter'); })
      ),expression:"\n        isOpen ? true : !overflowNavItems.some((item) => item.name == $t('Menu.Items.LogCenter'))\n      "}],attrs:{"name":_vm.$t('Menu.Items.LogCenter'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/log-center'),"icon":_vm.icons.LogCenterIcon,"data-redirect":"log-center","tooltipPlacement":"right","strokeHover":""},on:{"click":function () { return _vm.redirect('log-center'); }}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.isOpen ? true : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.Items.Shir'); })
      ),expression:"\n        isOpen ? true : !overflowNavItems.some((item) => item.name == $t('Menu.Items.Shir'))\n      "}],attrs:{"name":_vm.$t('Menu.Items.Shir'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/download-client'),"icon":_vm.icons.DownloadIcon,"data-redirect":"download-client","tooltipPlacement":"right","strokeHover":""},on:{"click":function () { return _vm.redirect('download-client'); }}}),_c('Extra',{directives:[{name:"show",rawName:"v-show",value:(_vm.overflowNavItems.length),expression:"overflowNavItems.length"}],attrs:{"items":_vm.overflowNavItems,"variant":_vm.variant}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }