<template>
  <div :class="['log-section', { open: isOpen }]">
    <div class="card-log">
      <div class="title-text">{{ titleText }}</div>
      <div :class="['content', { 'empty-state': isListEmpty }]">
        <div
          v-if="isListEmpty"
          class="empty-log-message"
        >
          <div class="empty-icon-message-container">
            <FilterNoResultIcon class="lupa-icon" />
            <div class="no-results-text">{{ emptyList }}</div>
          </div>
        </div>
        <component
          v-else
          :is="currentComponent"
          :listagem="currentList"
          @redirect="redirect"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ServiceLog from './ActivityLastLogs.vue';
  import IntegrationLog from './PipelineLastLogs.vue';
  import FilterNoResultIcon from '@/assets/images/icons/filter-no-result-icon.svg';
  import { mapMutations } from 'vuex';

  export default {
    components: {
      ServiceLog,
      IntegrationLog,
      FilterNoResultIcon
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false
      },
      showPipelines: {
        type: Boolean,
        default: true
      },
      filter: {
        type: Object,
        default() {
          return {
            startDate: '',
            endDate: '',
            days: 1
          };
        }
      },
      activityList: {
        type: Array,
        default() {
          return [];
        }
      },
      pipelineList: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    computed: {
      titleText() {
        return this.showPipelines
          ? this.$t('Dashboard.Logs.Pipeline')
          : this.$t('Dashboard.Logs.Activity');
      },
      emptyList() {
        return this.showPipelines
          ? this.$t('Dashboard.Logs.NoPipelineFound')
          : this.$t('Dashboard.Logs.NoActivityFound');
      },
      currentList() {
        return this.showPipelines ? this.pipelineList : this.activityList;
      },
      currentComponent() {
        return this.showPipelines ? 'IntegrationLog' : 'ServiceLog';
      },
      isListEmpty() {
        return this.currentList.length === 0;
      }
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      redirect(value) {
        if (this.$route.name === value.page) return;

        const prefix = this.$cookies.get('company');

        this.$router.push({
          name: value.page,
          query: {
            filterDays: this.filter.days,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
            id: value.id,
            activity: value.activity
          },
          params: { prefix }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .log-section {
    width: 100%;
    padding: 16px 0 1px;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
    border-radius: 6px;

    .card-log {
      width: 100%;

      .title-text {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        color: var(--colors-text-default-neutro-30);
        text-align: start;
        margin-bottom: 16px;
        margin-left: 16px;
      }

      .content {
        padding: 0 16px;
        overflow-y: auto;
        max-height: 541px;

        &::-webkit-scrollbar {
          width: 5px;
          border-radius: 50%;
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #fbeee8;
          margin-bottom: 16px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #cfc4be;
          border-radius: 20px;
        }
      }

      .empty-state {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 300px;
      }

      .empty-log-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        gap: 16px;
        width: 100%;

        .empty-icon-message-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        .lupa-icon {
          width: 16px;
          height: 16px;
          margin-bottom: 16px;
        }

        .no-results-text {
          font-family: 'Raleway', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          color: #998f8a;
          text-align: center;
          align-self: stretch;
        }
      }
    }
  }
</style>
